import styles from "./index.module.css";
import { MdDelete } from "react-icons/md";

interface ContentListTableProps {
    contentList: Record<string, any>[],
    removeContent: (content: Record<string, any>) => void
}

const ContentListTable: React.FC<ContentListTableProps> = ({ contentList, removeContent }) => {

    return (
        <div className={styles.added_content_list}>
            <table className={styles.content_list_table} >
                <thead>
                    <tr>
                        <th>Hours</th>
                        <th>Rate</th>
                    </tr>
                </thead>
                <tbody>
                    {contentList.map(content => {
                        return (
                            <tr key={content.hoursBeforeAppointmentTime}>
                                <td>{content.hoursBeforeAppointmentTime}</td>
                                <td>{content.applicableAppointmentChargeRate}</td>
                                <td>
                                    <button 
                                        className={styles.content_remove} 
                                        onClick={() => removeContent(content)}
                                    >
                                        <MdDelete 
                                            size={"1.2rem"} 
                                            color="#FFAAA5"
                                        />
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default ContentListTable;
