import { CiStickyNote } from "react-icons/ci";
import { VscWarning } from "react-icons/vsc";
import { ICalendarAppointment } from "../../types/pages/Calendar";
import styles from "./Calendar.module.css";

interface EventIconsProps {
    event: ICalendarAppointment
}

const EventIcons: React.FC<EventIconsProps> = ({ event }) => {

    return (
        <div className={styles.event_icons}>
            {
                (event.packages && event.packages.length > 0)
                ?
                <VscWarning 
                    size={"1rem"}
                    color={"white"}
                />
                :
                null
            }
            {
                (event.clientNote || event.note)
                ?
                <CiStickyNote 
                    size={"1rem"}
                    color={"white"}
                />
                :
                null
            }
        </div>
    );

}

export default EventIcons;