import styles from "./AppointmentModal.module.css";

const AppointmentStatus: React.FC<{ status?: string, cancelReason?: string }> = ({ status, cancelReason }) => {

    return (
        <div className = {styles.appointment_status_info}>
            <h5>Appointment Status:</h5>
            <span>{status}</span>
            {
                status === "Cancelled" && cancelReason
                ?
                <p>Cancel Reason - {cancelReason}</p>
                :
                null
            }
        </div> 
    );

}

export default AppointmentStatus;