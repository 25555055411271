import { useSelector, useDispatch } from "react-redux";
import { MdDelete } from "react-icons/md";
import { RootState } from "../../../store";
import { addRemoveWorkSchedule } from "../../../store/user-list/userListReducer";
import styles from "./AddEditUser.module.css";

const WorkScheduleTable: React.FC = () => {

    const dispatch = useDispatch();
    const userListState = useSelector((state: RootState) => state.userListState);
    const { newWorkSchedule } = userListState;

    const removeWorkSchedule = (day: string) => {
        const filteredWorkSchedule = newWorkSchedule.filter(item => item.day !== day);
        dispatch(addRemoveWorkSchedule(filteredWorkSchedule))
    }

    return (
        
        <div className = {styles.work_schedule_list}>
            <table className = {styles.work_schedule_table} >
                <thead>
                    <tr>
                        <th>Location</th>
                        <th>Day</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {newWorkSchedule.map(schedule => {

                        const { location, day, startTime, endTime } = schedule;

                        return (
                            <tr key = {day}>
                                <td>{location.title}</td>
                                <td>{day}</td>
                                <td>{startTime}</td>
                                <td>{endTime}</td>
                                <td>
                                    <button
                                        className={styles.remove_schedule} 
                                        onClick={() => removeWorkSchedule(day)}
                                    >
                                        <MdDelete 
                                            size={"1.2rem"} 
                                            color="#FFAAA5"
                                        />
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}


export default WorkScheduleTable;