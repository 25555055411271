import { IAppointment, AppointmentType } from "../../../types/models/Appointment";
import { ICalendarAppointment } from "../../../types/pages/Calendar";
import styles from "./AppointmentModal.module.css";
import SectionHeader from "./SectionHeader";
import AppointmentButtons from "./AppointmentButtons";
import AppointmentStatus from "./AppointmentStatus";
import AppointmentActivityList from "./AppointmentActivityList";

type AppointmentDetailsProps = {
    appointment: ICalendarAppointment | null
    canUserMaintainAgendas?: boolean
}

const AppointmentDetails: React.FC<AppointmentDetailsProps> = ({ appointment, canUserMaintainAgendas }) => {

    return (
        <div className = {styles.appointment_details}>
            <SectionHeader 
                headerText = "Appointment Details"
            />
            <div className = {styles.appointment_details_content}>
                <div className = {styles.appointment_basic_info}>
                    {
                        appointment?.type === AppointmentType.TimeBlock
                        ?
                        <p>{appointment.title} with {appointment.stylist.fullName}</p>
                        :
                        <p>{appointment?.treatment?.title} with {appointment?.stylist.fullName}</p>
                    }
                    { 
                        (appointment?.activities ?? []).length > 0
                        ?
                        <AppointmentActivityList 
                            activities={appointment?.activities ?? []} 
                        />
                        :
                        null
                    } 
                    {
                        (appointment?.location && canUserMaintainAgendas) 
                        ? 
                        <AppointmentButtons /> 
                        : 
                        null
                    }
                </div>
                <AppointmentStatus 
                    status={appointment?.status} 
                    cancelReason={appointment?.cancellation?.reason}
                />
            </div>
        </div>
    );

}

export default AppointmentDetails;